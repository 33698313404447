import { Inject, Injectable, Optional, afterNextRender, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { readerSiteMap } from './reader-sitemap.js';
import { writerSiteMap } from './writer-sitemap.js';
import { domainEnvironment, environment } from '@awread/global/environments';
import { HOST_AGENT, HOST_DEVICE } from './token.service.js';
import { LdJsonService } from './ld-json.service.js';
import { s3ClientUrl } from '@awread/kernel/tools';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class MetaTagService {
  ldJsonService = inject(LdJsonService);
  meta = inject(Meta);
  title = inject(Title);
  document = inject(DOCUMENT);
  website = `https://${this.document.location.host}`;
  imageServer = `${s3ClientUrl}`;
  hostAgent = inject(HOST_AGENT, { optional: true });
  hostDevice = inject(HOST_DEVICE, { optional: true });

  constructor() {
    console.log('issue on http protol cause redirect', this.document.location.protocol);
    this.start();
    afterNextRender(() => {
      console.log('issue on http protol cause redirect', this.document.location.protocol, this.document.baseURI);
      // Agent detection logic can be implemented here
      // const userAgent = this.document.navigator.userAgent;
      // const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    })
  }

  start() {
    // console.log(`What is your website?`, this.website);
    this.commonMetaTag();
  }

  previewImage(previewPath: 'preview-book' | 'preview-area' | 'preview-author' = 'preview-book', id: string, updatedAt = '') {
    const image = `${this.website}/v1/api/${previewPath}/${updatedAt ? new Date(updatedAt).getTime() : 'default'}/${id}.webp`;
    const tags = [
      { name: 'image', content: image },
      { name: 'thumbnail', content: image },
      { property: 'og:image', content: image },
    ];
    this.updateTags(tags);
  }

  commonMetaTag() {
    // console.log('HOST_AGENT, HOST_DEVICE 1', HOST_AGENT, HOST_DEVICE);
    // console.log('robot host?', this.host, this.host.includes('awread.com'));
    // console.log('test robots?', this.host.includes('awread.com') ? 'noindex' : 'index, follow');
    this.meta.addTag({ name: 'robots', content: this.website?.includes('awread.com') ? 'noindex' : 'index, follow' });
    this.meta.addTag({ name: 'language', content: 'vi_VN' });
    this.meta.addTag({ name: 'audience', content: 'general' });
    this.meta.addTag({ property: 'og:locale', content: 'vi' });
    if (environment.production) {
      // this.meta.updateTag({ 'http-equiv': 'Content-Security-Policy', content: 'upgrade-insecure-requests' });
    }
  }

  autoSetMetaTagOnRouteEnd(url: string, title: string | undefined) {
    const siteMap = domainEnvironment.domain === 'writer' ? writerSiteMap : readerSiteMap;
    const item = siteMap.find((i) => url === i.customUrl) ?? { title: null, description: null, image: null };
    this.setCommonTag(title || item.title, item.description, item.image);
  }

  fb({ chapter, author, book }: {
    chapter: any;
    author: { username: string, userId: string, description: string, title: string, images: string[] }; book: any
  }, options?: { customTitle?: any; noIndex?: boolean }) {
    chapter = chapter ?? {};
    author = author ?? {} as any;
    book = book ?? {};
    options = options ?? {};
    const chapterUrl = chapter.chapterNid ? `${this.website}/c/` + chapter.chapterNid : null;
    const bookUrl = book.bookNid ? `${this.website}/b/` + book.bookNid : null;
    const authorUrl = author.username ? `${this.website}/a/` + author.username : null;
    const bookImage = book.bookId && book.cover ? this.imageServer + `/books/xl/${book.bookId}.webp?updatedAt=${book.updatedAt}` : null;
    const authorImage = author.images ? author.images[0] : null;

    let tags: any[] = [];
    const title = options.customTitle ?? chapter.title ?? book.title ?? author.title;
    const description = chapter.description ?? book.description ?? author.description;
    const image = bookImage ?? authorImage;
    // console.log(' WTF FB?', title, description, image);
    const url = chapterUrl ?? bookUrl ?? authorUrl;
    // const published_time = chapter.publishedAt ?? book.publishedAt ?? null;
    // const modified_time = chapter.updatedAt ?? book.updatedAt ?? null;
    // if (published_time) {
    //   tags.push({ name: 'article:published_time', content: '' });
    // }
    // if (modified_time) {
    //   tags.push({ name: 'article:modified_time', content: '' });
    // }

    if (options?.noIndex) {
      tags.push({ name: 'robots', content: 'noindex' });
    }

    tags = tags.concat([
      { property: 'cannonical', content: url },
      { property: 'og:url', content: url },
      { property: 'article:author', content: author.title },
      { property: 'article:section', content: 'book' },
      { property: 'article:tag', content: `awread, ${title}` },
    ]);
    this.setCommonTag(title, description, image);
    this.updateTags(tags);
  }

  updateTags(tags: any[]) {
    for (const tag of tags) {
      tag && this.meta.updateTag(tag);
    }
  }

  setCommonTag(title: string | null = '', description: string | null = '', image: string | null = '', options?: { article?: boolean }) {
    let tags: any[] = [];
    title = title || readerSiteMap[0].title;
    description = description || title;
    image = image || readerSiteMap[0].image;
    // facebook
    // console.log('title desc', title, description);
    tags = tags.concat([{ property: 'fb:app_id', content: '178697176886410' }]);
    tags = tags.concat([{ property: 'og:type', content: options?.article ? 'article' : 'website' }]);
    tags = tags.concat([{ property: 'og:locale', content: 'vi_VN' }]);
    // title
    this.title.setTitle(title);

    tags = tags.concat([
      { property: 'og:title', content: title },
      { property: 'og:image:alt', content: title },
    ]);
    // description
    tags = tags.concat([
      { name: 'title', content: title },
      { name: 'description', content: description.slice(0, 150) },
      { property: 'og:description', content: description.slice(0, 150) },
      {
        name: 'keywords',
        content: `${title}`,
      },
    ]);
    // image
    tags = tags.concat([
      { name: 'image', content: image },
      { name: 'thumbnail', content: image },
      { property: 'og:image', content: image },
    ]);
    // url
    tags = tags.concat([{ property: 'og:url', content: this.document.URL }]);
    this.updateTags(tags);
  }
}
